import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/authentication/Login'
import Register from '../views/authentication/Register'
import Profil from '../views/utilisateur/profil'
import UtilisateurInformations from '../views/utilisateur/utilisateur-informations'
import UtilisateurSoldes from '../views/utilisateur/utilisateur-soldes'
import Demande from '../views/demande/demande'
import DemandeCongesDetail from '../views/demande/demande-conges-detail'
import DemandeHeuresSuppDetail from '../views/demande/demande-heures-supp-detail'
import DemandeRecuperationDetail from '../views/demande/demande-recuperation-detail'
import Employe from '../views/employe/employe'
import EmployeDetail from '../views/employe/employe-detail'
import CongesSoldeDetail from '../components/conges/solde/conges-solde-detail'
import DetailSoldeDeRecuperation from '../components/employe/soldeDeRecuperation/detail-solde-de-recuperation.vue'
import EmployeDetailContrat from '../views/employe/contrat/employe-detail-contrat'
import EmployeCreate from '../views/employe/employe-create'
import EtablissementPage from '../views/etablissement/etablissement'
import AssociationPage from '../views/etablissement/association'
import AssociationDetail from '../views/etablissement/association-detail'
import EtablissementDetail from '../views/etablissement/etablissement-detail.vue'
import EtablissementTableauConges from '../views/etablissement/etablissement-tableau-conges'
import ServicePage from '../views/service/service'
import ServiceDetail from '../views/service/service-detail'
import ServicePlanning from '../views/service/service-planning'
import ModelePlanning from '../views/modele-planning/modele-planning'
import Planning from '../views/planning/planning'
import Test from '@/views/Test.vue'
import Usagers from '@/views/usager/usagers.vue'
import UsagerDetail from '@/views/usager/usager-detail.vue'
import HistoriqueAccueilUsager from '@/views/usager/HistoriqueAccueilUsager.vue'
import FichesAimUsager from '@/views/ficheAIM/fiches-aim-usager.vue'
import FicheAim from '@/views/ficheAIM/consultation-fiche-aim.vue'
import PreparationFicheAim from '@/views/ficheAIM/preparation-fiche-aim.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/test',
      name: 'test',
      component: Test,
    },
    {
      path: '/profil',
      name: 'profil',
      component: Profil,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'informations',
          name: 'utilisateur-informations',
          component: UtilisateurInformations,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'soldes',
          name: 'utilisateur-soldes',
          component: UtilisateurSoldes,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: 'employe/:employeId/conges/:congesTypeId',
              name: 'UtilisateurDetailsCongesSolde',
              component: CongesSoldeDetail,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'employe/:employeId/recuperation',
              name: 'UtilisateurDetailsRecuperation',
              component: DetailSoldeDeRecuperation,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/demande',
      name: 'demande',
      component: Demande,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: ':demandeId/conges',
          name: 'demandeCongesDetail',
          component: DemandeCongesDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: ':demandeId/heures-supp',
          name: 'demandeHeuresSuppDetail',
          component: DemandeHeuresSuppDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: ':demandeId/recuperation',
          name: 'demandeRecuperationDetail',
          component: DemandeRecuperationDetail,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/tableau-des-conges',
      name: 'EtablissementTableauConges',
      component: EtablissementTableauConges,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/employes',
      name: 'employes',
      component: Employe,
      meta: {
        requiresAuth: true,
        permission: 'ADMINISTRATEUR',
      },
      children: [
        {
          path: 'create',
          name: 'employeCreate',
          component: EmployeCreate,
          meta: {
            requiresAuth: true,
            permission: 'ADMINISTRATEUR',
          },
        },
        {
          path: ':employeId',
          name: 'employe',
          component: EmployeDetail,
          meta: {
            requiresAuth: true,
            permission: 'ADMINISTRATEUR',
          },
          children: [
            {
              path: 'conges/:congesTypeId',
              name: 'EmployeDetailsCongesSolde',
              component: CongesSoldeDetail,
              meta: {
                requiresAuth: true,
                permission: 'ADMINISTRATEUR',
              },
            },
            {
              path: 'recuperation',
              name: 'RecuperationDetails',
              component: DetailSoldeDeRecuperation,
              meta: {
                requiresAuth: true,
                permission: 'ADMINISTRATEUR',
              },
            },
            {
              path: 'contrat',
              name: 'EmployeDetailContrat',
              component: EmployeDetailContrat,
              meta: {
                requiresAuth: true,
                permission: 'ADMINISTRATEUR',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/services',
      name: 'services',
      component: ServicePage,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: ':serviceId',
          name: 'service',
          component: ServiceDetail,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/etablissement',
      name: 'etablissement',
      component: EtablissementDetail,
      meta: {
        requiresAuth: true,
        permission: 'SALARIE',
      },
    },
    {
      path: '/etablissements',
      name: 'etablissements',
      component: EtablissementPage,
      meta: {
        requiresAuth: true,
        permission: 'Administrateur',
      },
      children: [
        {
          path: ':etablissementId',
          name: 'etablissement',
          component: EtablissementDetail,
          meta: {
            requiresAuth: true,
            permission: 'Administrateur',
          },
        },
      ],
    },
    {
      path: '/association',
      name: 'associations',
      component: AssociationPage,
      meta: {
        requiresAuth: true,
        permission: 'Administrateur',
      },
      children: [
        {
          path: ':associationId',
          name: 'association',
          component: AssociationDetail,
          meta: {
            requiresAuth: true,
            permission: 'Administrateur',
          },
        },
      ],
    },
    {
      path: '/services/:serviceId/modelePlanning',
      name: 'modelePlanning',
      component: ModelePlanning,
      meta: {
        requiresAuth: true,
        permission: 'SALARIE',
      },
    },
    {
      path: '/services/:serviceId/planning/:numeroSemaine?',
      name: 'service-planning',
      component: ServicePlanning,
      meta: {
        requiresAuth: true,
        permission: 'SALARIE',
      },
    },
    {
      path: '/planning',
      name: 'planningUtilisateur',
      component: Planning,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        requiresAuth: true,
        permission: 'Administrateur',
      },
    },
    {
      path: '/fiche-aim/:ficheAimId',
      name: 'fiche-aim',
      component: FicheAim,
      meta: {
        requiresAuth: true,
        permission: 'Administrateur',
      },
    },
    {
      path: '/preparation-fiche-aim/:ficheAimId',
      name: 'preparation-fiche-aim',
      component: PreparationFicheAim,
      meta: {
        requiresAuth: true,
        permission: 'Administrateur',
      },
    },
    {
      path: '/usagers',
      name: 'usagers',
      component: Usagers,
      meta: {
        requiresAuth: true,
        permission: 'Administrateur',
      },
      children: [
        {
          path: ':usagerId',
          name: 'usager',
          component: UsagerDetail,
          meta: {
            requiresAuth: true,
            permission: 'Administrateur',
          },
          children: [
            {
              path: 'accueils',
              name: 'historique-accueils-usager',
              component: HistoriqueAccueilUsager,
              meta: {
                requiresAuth: true,
                permission: 'Administrateur',
              },
            },
            {
              path: 'fiches-aim',
              name: 'fiches-aim-usager',
              component: FichesAimUsager,
              meta: {
                requiresAuth: true,
                permission: 'Administrateur',
              },
            },
          ],
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const loggedIn = JSON.parse(localStorage.getItem('user'))
  const selectedEtablissement = JSON.parse(localStorage.getItem('selectedEtablissement'))
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({
      name: 'login',
      query: {
        nextUrl: to.fullPath,
      },
    })
  } else {
    if (
      //La route requiert des permissions ET l'utilisateur n'a pas le bon rôle ET l'utilisateur n'a pas le rôle d'administrateur
      (to.matched.some((record) => record.meta.permission) &&
        !loggedIn.roles.includes('ROLE_' + to.meta.permission) &&
        !loggedIn.roles.includes(
          'ROLE_' + to.meta.permission + '_' + (selectedEtablissement ? selectedEtablissement.etablissementId : '')
        ) &&
        !loggedIn.roles.includes(
          'ROLE_' + to.meta.permission + '_' + (selectedEtablissement ? selectedEtablissement.siegeSocialId : '')
        ) &&
        !loggedIn.roles.includes('ROLE_Administrateur')) ||
      //OU la route demandée est celle du login
      to.fullPath === '/'
    ) {
      next('/planning')
    } else {
      next()
    }
  }
})

export default router
