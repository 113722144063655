<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <template v-if="employe.recuperations">
          <v-toolbar-title class="text-center"> À venir ( {{ soldeDeRecuperationAVenir / 60 }}h ) </v-toolbar-title>
          <v-divider />
          <detail-solde-de-recuperation-a-venir-list
            :details-par-date-a-venir="employe.recuperations.detailsParDateAVenir"
          />
          <v-toolbar-title class="text-center">
            À date ( {{ employe.recuperations.soldeDeRecuperationADate / 60 }}h )
          </v-toolbar-title>
          <v-divider />
          <detail-solde-de-recuperation-passe-list
            :details-par-date-a-passe="employe.recuperations.detailsParDatePasse"
          />
          <v-divider />
          <v-footer padless color="white">
            <v-col class="text-center text-body-1" cols="12">
              Solde de récupération initial: {{ employe.recuperations.soldeRecupInitial / 60 }}h
            </v-col>
          </v-footer>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DetailSoldeDeRecuperationAVenirList from '@/components/employe/soldeDeRecuperation/detail-solde-de-recuperation-a-venir-list.vue'
import DetailSoldeDeRecuperationPasseList from '@/components/employe/soldeDeRecuperation/detail-solde-de-recuperation-passe-list.vue'

export default {
  name: 'employe-detail-recuperation',
  components: {
    DetailSoldeDeRecuperationAVenirList,
    DetailSoldeDeRecuperationPasseList,
  },
  data() {
    return {
      selectedSolde: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
    }),
    employe() {
      return this.getEmployeById(parseInt(this.$route.params.employeId, 10))
    },
    soldeDeRecuperationAVenir() {
      return this.employe.recuperations.soldeDeRecuperationTotal - this.employe.recuperations.soldeDeRecuperationADate
    },
  },
}
</script>

<style scoped>
.recuperations-toolbar {
  border-bottom: 2px solid var(--v-primary-lighten5);
}
</style>
